import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { useScreening } from '@nodal/core/flows/Screening';
import { InsuranceReview } from '@nodal/core/flows/Screening/InsuranceReview/InsuranceReview';
import { useInsuranceReview } from '@nodal/core/flows/Screening/InsuranceReview/useInsuranceReview';
import { useGooglePlacesApi } from '@nodal/core/hooks/useGooglePlacesApi';
import { useUploadInsuranceDocuments } from '@nodal/core/hooks/useUploadInsuranceDocuments';
import { useUser } from '@nodal/core/hooks/useUser';
import { t } from '@nodal/i18n';
import { useConfirmationDialog } from '@nodal/uikit/components/ConfirmationDialog';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { useQuery } from 'react-query';

const title = t('Insurance Review');
const description = t(
  'Please provide information below to help us evaluate your health insurance coverage as it relates to your surrogacy',
);

export const InsuranceReviewConnected = () => {
  const placesApiReady = useGooglePlacesApi();
  const { currentStep, submit } = useScreening();
  const confirm = useConfirmationDialog();
  const apiClient = useApiClient();
  const { user } = useUser();

  const { data: insuranceDocuments } = useQuery(
    queryKeys.insuranceDocumentsList,
    () =>
      apiClient.api.MedicalReviewsApi.medicalReviewsInsuranceDocumentsOwnList(),
  );

  const { initialValues, onSubmit, validationSchema } = useInsuranceReview({
    submit,
    screenId: currentStep?.id,
  });

  const { onDelete, onUpload, uploadedFiles, disabled } =
    useUploadInsuranceDocuments({
      documents: insuranceDocuments?.data || [],
      confirm,
      documentType: apiEnums.MedicalDocumentDocumentTypeEnum.SurroInsDoc,
      medicalReviewId: user?.medical_review_id,
    });

  if (!placesApiReady || !currentStep) {
    return <LoadingScreen />;
  }

  return (
    <InsuranceReview
      initialValues={{
        ...initialValues,
        id: currentStep.id,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      title={title}
      description={description}
      editable
      previewPhotoEnabled={false}
      documentsControl={{
        onUpload,
        onDelete,
        uploadedFiles,
        disabled,
      }}
    />
  );
};

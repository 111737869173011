import { ApiModel, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { objectToFormData } from '@nodal/core/utils';
import { useUsersMeRetrieve } from 'api/hooks/useUsersMeRetrieve';
import { FormikValues } from 'formik';
import { useCallback, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { t } from '@nodal/i18n';

export const useUserProfile = () => {
  const apiClient = useApiClient();
  const userProfile = useUsersMeRetrieve();
  const screensList = useQuery(queryKeys.screensList, () =>
    apiClient.api.ScreensApi.screensList(),
  );

  const { data: userData } = userProfile || {};

  const [uploadProgress, setUploadProgress] = useState<number>();
  const updateProfile = useMutation(
    (requestParameters: {
      patchedProfileObject:
        | ApiModel.PatchedDonorProfile
        | ApiModel.PatchedParentsProfile
        | ApiModel.PatchedNavigatorParentsProfile;
    }) => {
      const multipart = !!requestParameters.patchedProfileObject?.video;

      return apiClient.api.UsersApi.usersMeProfilePartialUpdate(
        multipart
          ? {
              patchedProfileObject: objectToFormData(
                requestParameters.patchedProfileObject,
              ) as ApiModel.PatchedProfileObject,
            }
          : requestParameters,
        {
          headers: {
            'Content-Type': multipart
              ? 'multipart/form-data'
              : 'application/json',
          },
          onUploadProgress: (e) => {
            if (e.total) {
              setUploadProgress(e.loaded / e.total);
            }
          },
        },
      );
    },
  );

  const screenSubmit = useMutation(
    (request: ApiModel.ScreensApiScreensSubmitCreateRequest) =>
      apiClient.api.ScreensApi.screensSubmitCreate(request),
  );

  const uploadInsurancePhotos = useMutation(
    (request: ApiModel.ScreensApiScreensInsuranceDocumentUpdateRequest) =>
      apiClient.api.ScreensApi.screensInsuranceDocumentUpdate(request, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    {
      onError: () => {
        toast.error(t('Failed to upload insurance photos'));
      },
    },
  );

  const deleteInsurancePhotos = useMutation(
    (request: ApiModel.ScreensApiScreensInsuranceDocumentUpdateRequest) =>
      apiClient.api.ScreensApi.screensInsuranceDocumentUpdate(request),
  );

  const handleInsuranceUpdate = useCallback(
    async (data: FormikValues) => {
      const insuranceReviewScreen = screensList.data?.data.find(
        (screen) => screen.uid === 'insurance-review',
      );
      const { no_insurance, insurance_photo_front, insurance_photo_back } =
        data;

      if (!no_insurance) {
        if (
          typeof insurance_photo_front !== 'string' &&
          typeof insurance_photo_back !== 'string'
        ) {
          await uploadInsurancePhotos.mutateAsync({
            id: Number(insuranceReviewScreen?.id),
            medicalRecordReviewScreenDocuments: {
              insurance_photo_front: insurance_photo_front || null,
              insurance_photo_back: insurance_photo_back || null,
            },
          });
        }
      } else {
        await deleteInsurancePhotos.mutateAsync({
          id: Number(insuranceReviewScreen?.id),
          medicalRecordReviewScreenDocuments: {
            insurance_photo_front: null,
            insurance_photo_back: null,
          },
        });
      }

      await screenSubmit.mutateAsync({
        id: insuranceReviewScreen!.id,
        ...insuranceReviewScreen,
        screenContentObject: {
          health_insurance: no_insurance ? null : data.health_insurance,
        } as ApiModel.InsuranceReviewScreen,
      });
    },
    [
      screensList.data?.data,
      screenSubmit,
      uploadInsurancePhotos,
      deleteInsurancePhotos,
    ],
  );

  const submit = useCallback(
    async (data: FormikValues) => {
      try {
        if (data.section === 'insuranceReview') {
          await handleInsuranceUpdate(data);
        } else {
          const submitResponse = await updateProfile.mutateAsync({
            patchedProfileObject: data,
          });

          return submitResponse?.data;
        }

        await userProfile.refetch();
      } catch (error) {
        // TODO: remove this if we will have error handling
        return console.error(error);
      }
    },
    [handleInsuranceUpdate, updateProfile, userProfile],
  );

  return {
    profileData: userData?.data,
    submit,
    uploadProgress,
  };
};

import { t } from '@nodal/i18n';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { Checkbox } from '@nodal/uikit/components/Checkbox';
import { FileDropZone } from '@nodal/uikit/components/FileDropZone';
import { ImageInput } from '@nodal/uikit/components/ImageInput';
import { TextInput } from '@nodal/uikit/components/TextInput';
import { UploadedFileList } from '@nodal/uikit/components/UploadedFileList';
import { Button } from '@nodal/uikit/src/components/Button';
import { Form, Formik } from 'formik';
import { type FC } from 'react';

import type { InsuranceReviewProps } from './InsuranceReview.interface';

export const InsuranceReview: FC<InsuranceReviewProps> = ({
  initialValues,
  onSubmit,
  validationSchema,
  title,
  description,
  editable,
  previewPhotoEnabled,
  documentsControl,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {(formikHelpers) => {
        const { values, setFieldValue, isSubmitting } = formikHelpers;

        return (
          <Form className="space-y-4 w-full lg:w-step-view">
            <div className="p-8 bg-white rounded-lg border border-grey-forest-200">
              <div className="flex flex-col gap-4 pb-6 w-full text-left">
                <h3 className="text-2xl font-semibold leading-8 text-grey-forest-900">
                  {title}
                </h3>
                <p className="text-base font-normal leading-6 text-grey-forest-600">
                  {description}
                </p>
              </div>

              <div className="flex flex-col gap-x-4 gap-y-10 mt-4">
                <Checkbox
                  label={t("I don't have health insurance")}
                  name="no_insurance"
                  disabled={!editable}
                  onChange={(e) => {
                    setFieldValue('no_insurance', e.target.checked);
                    if (e.target.checked) {
                      setFieldValue('health_insurance', '');
                      setFieldValue('insurance_photo_front', null);
                      setFieldValue('insurance_photo_back', null);
                    }
                  }}
                />
                {!values.no_insurance && (
                  <>
                    <TextInput
                      label={t('Who is your health insurance provider?')}
                      name="health_insurance"
                      type="text"
                      disabled={!editable}
                    />
                    <div className="flex flex-col w-full">
                      <label className="block text-sm font-medium text-gray-700">
                        {t(
                          'Please upload a photo of the front and back of your health insurance card',
                        )}
                      </label>
                      <div className="flex gap-4 justify-between w-full">
                        <div className="m-1 w-full text-center">
                          <p className="block mt-1 text-sm text-gray-500">
                            {t('Front')}
                          </p>
                          <ImageInput
                            name="insurance_photo_front"
                            className="h-40"
                            disabled={!editable}
                            previewEnabled={previewPhotoEnabled}
                          />
                        </div>
                        <div className="m-1 w-full text-center">
                          <p className="block mt-1 text-sm text-gray-500">
                            {t('Back')}
                          </p>
                          <ImageInput
                            name="insurance_photo_back"
                            className="h-40"
                            disabled={!editable}
                            previewEnabled={previewPhotoEnabled}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {documentsControl && (
              <div className="p-8 space-y-6 bg-white rounded-lg border border-grey-forest-200">
                <p className="text-xl font-semibold text-grey-forest-900">
                  {t('Insurance Documents')}
                </p>
                <p className="pb-6 text-base font-normal leading-6 text-grey-forest-600">
                  {t(
                    'As part of the medical review process, we collect all previous medical records from your pregnancies and deliveries for review. If you have access to any past medical records please upload them below. This will help speed up the medical review process.',
                  )}
                </p>
                <div className="flex flex-col col-span-2 row-start-1 rounded-lg border border-grey-forest-100">
                  <div className="border-b border-grey-forest-100">
                    <FileDropZone
                      onUpload={documentsControl.onUpload}
                      disabled={documentsControl.disabled}
                      acceptFileTypes="image/*, .pdf, .doc, .docx"
                    />
                  </div>
                  <UploadedFileList
                    files={documentsControl.uploadedFiles}
                    onDelete={documentsControl.onDelete}
                    uploaderIconVisible={false}
                  />
                </div>
              </div>
            )}
            {editable && (
              <div className="flex justify-end">
                <Button
                  type="button"
                  variant="secondary"
                  className="mr-4 bg-transparent hover:bg-transparent shadow-none"
                  onClick={() => {
                    const submitData = {
                      ...values,
                      complete_later: true,
                    };
                    onSubmit(submitData, formikHelpers);
                  }}
                >
                  {t('Complete this section later')}
                </Button>
                <ButtonLoading
                  type="submit"
                  variant="primary"
                  loading={isSubmitting}
                  className="justify-center px-6 w-full sm:w-max"
                >
                  {t('Submit')}
                </ButtonLoading>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

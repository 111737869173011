import { useEffect } from 'react';
import type { FC } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { apiEnums, ApiModel } from '@nodal/api';
import { Screening } from './Screening';
import { paths, screeningPaths } from 'consts/paths';
import { useGooglePlacesApi } from '@nodal/core/hooks/useGooglePlacesApi';
import { useUsersMeRetrieve } from 'api/hooks/useUsersMeRetrieve';
import {
  ScreeningContextProvider,
  useScreening,
} from '@nodal/core/flows/Screening';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { settings } from 'settings';

const stepToPath = new Map<string, string>([
  ['parents-profile', screeningPaths.profile],
  ['donor-profile', screeningPaths.profile],
  ['navigator-parents-profile', screeningPaths.profile],
  ['parents-questionnaire', screeningPaths.questions],
  ['donor-questionnaire', screeningPaths.questions],
  ['stripe-identity', screeningPaths.identity],
  ['phone-call', screeningPaths.phone],
  ['hipaa-consent-form', screeningPaths.signing],
  ['medical-record-review', screeningPaths.medical],
  ['checkr', screeningPaths.background],
  ['insurance-review', screeningPaths.insurance],
]);

const roleToNextPath = new Map<ApiModel.UserRoleEnum, string>([
  [apiEnums.UserRoleEnum.Dnr, paths.matching],
  [apiEnums.UserRoleEnum.Par, paths.matching],
  [
    apiEnums.UserRoleEnum.Par,
    settings.getIpDashboardFeatureEnabled() ? paths.dashboard : paths.matching,
  ],
  [apiEnums.UserRoleEnum.Nap, paths.navigator],
]);

const ScreeningContextConsumer: FC = () => {
  const { currentStep, steps, submit } = useScreening();
  const { data: userData } = useUsersMeRetrieve();

  const placesApiReady = useGooglePlacesApi();

  if (!userData?.data.role || !steps?.length || !placesApiReady || !currentStep)
    return <LoadingScreen />;

  return (
    <Screening
      steps={steps}
      activeStepIndex={currentStep.index}
      submit={submit}
      userData={userData.data}
    />
  );
};

const ScreeningInitialRedirect: FC = () => {
  const { started, completed, steps, currentStep, refresh } = useScreening();
  const { data: userData } = useUsersMeRetrieve();
  const { role, status } = userData?.data || {};
  const navigate = useNavigate();

  useEffect(() => {
    refresh();
    // NOTE: Only on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const nextPath = roleToNextPath.get(role!);

    if (completed && nextPath) {
      navigate(nextPath);
      return;
    }

    // NOTE: We cannot determine yet where we are.
    if (!currentStep || !steps.length) {
      // TODO: Show Loading...?
      return;
    }

    const allPending = steps.every(
      (s) => s.status === apiEnums.ScreenStatusEnum.Pend,
    );

    if (allPending && !started) {
      if (
        settings.getIpDashboardFeatureEnabled() &&
        role === apiEnums.UserRoleEnum.Par
      )
        return;

      navigate(screeningPaths.start);
      return;
    }

    if (!allPending || started) {
      const path =
        status === apiEnums.UserStatusEnum.Dis ||
        status === apiEnums.UserStatusEnum.Dea
          ? screeningPaths.disqualified
          : stepToPath.get(currentStep.type) || screeningPaths.start;
      navigate(path);
      return;
    }
  }, [navigate, steps, currentStep, started, completed, role, status]);

  return <></>;
};

export const ScreeningConnected: FC = () => (
  <ScreeningContextProvider>
    <Routes>
      <Route path="*" element={<ScreeningInitialRedirect />} />
    </Routes>

    <ScreeningContextConsumer />
  </ScreeningContextProvider>
);

import React, { FC, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { MatchProfileConnectedProps } from './MatchProfile.interface';
import {
  MatchProfile,
  MatchProfileData,
  MatchProfileSkeleton,
  dnrProfileInformation,
  parProfileInformation,
} from '@nodal/core/flows/MatchProfile';
import type { SectionTypes } from '@nodal/core/flows/MatchProfile';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ApiModel, useApiClient, apiEnums, GlobalError } from '@nodal/api';
import type { DonorUser, ParentsUser, IntroductionPatched } from '@nodal/api';
import { convertToPdf } from 'utils/pdf';
import { toast } from 'react-toastify';
import { t } from '@nodal/i18n';
import { useModal } from '@nodal/uikit/components/Modal';
import {
  buildProfileInformationWithSections,
  buildScreeningDetails,
} from '@nodal/core/flows/MatchProfile/utils';
import { EditPhotos } from './EditPhotos';
import { EditForm } from './EditForm';
import { NotificationTopBar } from './NotificationTopBar';
import { queryKeys } from '@nodal/core/consts/query';

export const useScreeningDetails = (
  userId: number,
  userRole: ApiModel.UserRoleEnum,
) => {
  const apiClient = useApiClient();

  const { data: medicalReviewUserRetrieve } = useQuery(
    queryKeys.medicalReviewsMatchProfileRetrieve,
    () =>
      apiClient.api.MedicalReviewsApi.medicalReviewsMatchProfileRetrieve({
        userId,
      }),
    {
      enabled: userRole === apiEnums.UserRoleEnum.Dnr,
    },
  );

  const { data: screensUsersRetrieve } = useQuery(
    queryKeys.screensForIntroductionUsersRetrieve,
    () =>
      apiClient.api.ScreensApi.screensForIntroductionUsersList({
        userId,
      }),
    {
      enabled: userRole === apiEnums.UserRoleEnum.Dnr,
    },
  );

  const { data: checkrScreenUsersRetrieve } = useQuery(
    queryKeys.checkrScreenForIntroductionUsersRetrieve,
    () =>
      apiClient.api.ScreensApi.screensCheckrScreenForIntroductionUsersRetrieve({
        userId,
      }),
    {
      enabled: userRole === apiEnums.UserRoleEnum.Dnr,
    },
  );

  const screeningDetails =
    screensUsersRetrieve?.data &&
    medicalReviewUserRetrieve?.data &&
    checkrScreenUsersRetrieve?.data
      ? buildScreeningDetails({
          screens: screensUsersRetrieve.data,
          medicalReviewSteps: medicalReviewUserRetrieve.data.steps,
          partnersBackgroundCheckStatuses:
            checkrScreenUsersRetrieve.data.candidates
              .filter(
                (candidate) =>
                  candidate.role === apiEnums.RoleFd4Enum.Secondary,
              )
              .map((candidate) => candidate?.report_status),
        })
      : undefined;

  return { screeningDetails };
};

const useFavoriteProfile = (
  role: MatchProfileData['role'],
  profileId: MatchProfileData['id'],
  owner: boolean,
) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const { mutateAsync: addToFavorite } = useMutation(
    'addToFavorite',
    (requestParams: ApiModel.FavoriteParentsApiFavoriteParentsCreateRequest) =>
      apiClient.api.FavoriteParentsApi.favoriteParentsCreate(requestParams),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeys.favoriteParentsList),
    },
  );

  const { mutateAsync: removeFromFavorite } = useMutation(
    'removeFromFavorite',
    (requestParams: ApiModel.FavoriteParentsApiFavoriteParentsDestroyRequest) =>
      apiClient.api.FavoriteParentsApi.favoriteParentsDestroy(requestParams),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeys.favoriteParentsList),
    },
  );

  const shouldProfileHaveFavoritesButton =
    !owner && role === apiEnums.UserRoleEnum.Par;

  const { data: favoriteParentsList } = useQuery(
    queryKeys.favoriteParentsList,
    () => apiClient.api.FavoriteParentsApi.favoriteParentsList(),
    {
      enabled: shouldProfileHaveFavoritesButton,
    },
  );

  if (!favoriteParentsList?.data && shouldProfileHaveFavoritesButton) {
    return;
  }

  const favoriteProfile = favoriteParentsList?.data?.find(
    (parent) => parent.parent_profile.id === profileId,
  );

  const onClickFavorite = async () => {
    if (favoriteProfile) {
      await removeFromFavorite({ likedParent: profileId });
    } else {
      await addToFavorite({
        // NOTE: Bug in Api Schema - parent_profile and donor is required
        // in the Schema, but not in the payload data
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        userFavoriteParents: {
          liked_parent: profileId,
        },
      });
    }
  };

  return {
    onClickFavorite,
    isFavoriteProfile: !!favoriteProfile,
  };
};

const useMatchProfile = (
  profileData: MatchProfileConnectedProps['profileData'],
) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { open, close } = useModal();

  const { data: userMeResponse } = useQuery(queryKeys.usersMeRetrieve, () =>
    apiClient.api.UsersApi.usersMeRetrieve(),
  );
  const userMe = userMeResponse?.data as DonorUser | ParentsUser | undefined; // NOTE: Issue in the API schema

  const generateIntroductionProfiles = (
    userMeData: DonorUser | ParentsUser,
  ) => {
    switch (profileData.role) {
      case apiEnums.UserRoleEnum.Dnr:
        return {
          donor_profile: {
            ...profileData.profile,
            user: {
              profile_photos: profileData.profile_photos,
            },
          },
          parent_profile: {
            ...userMeData.profile,
            user: {
              profile_photos: userMeData.profile_photos,
            },
          },
        };

      case apiEnums.UserRoleEnum.Par:
        return {
          donor_profile: {
            ...userMeData.profile,
            user: {
              profile_photos: userMeData.profile_photos,
            },
          },
          parent_profile: {
            ...profileData.profile,
            user: {
              profile_photos: profileData.profile_photos,
            },
          },
        };
    }
  };

  const introductionProfiles = userMe
    ? generateIntroductionProfiles(userMe)
    : undefined;

  const introduction: IntroductionPatched | undefined =
    'introduction' in profileData &&
    profileData?.introduction &&
    introductionProfiles
      ? {
          ...profileData.introduction,
          ...introductionProfiles,
        }
      : undefined;

  const { mutateAsync: requestIntroduction } = useMutation(
    'requestIntroduction',
    () => {
      // NOTE: Only marketplace users have known id
      if (!('id' in profileData)) throw new Error('Unknown user id');
      return apiClient.api.IntroductionsApi.introductionsCreate({
        createIntroduction: { parent_id: profileData.id },
      });
    },
    {
      onSuccess: () =>
        // NOTE: match profile is displayed in many views and the data of these queries
        // contains an introduction or introductions_left property that needs to be updated
        Promise.all([
          queryClient.invalidateQueries(queryKeys.marketplaceParentsList),
          queryClient.invalidateQueries(queryKeys.favoriteParentsRetrieve),
          queryClient.invalidateQueries(queryKeys.favoriteParentsList),
          queryClient.invalidateQueries(queryKeys.usersMeRetrieve),
        ]),
      onError: (error: GlobalError) => {
        console.log(error?.response?.data);
        if (error?.response?.data) {
          toast.error(t('Something went wrong...'));
        }
      },
    },
  );

  const { mutateAsync: updateIntroduction } = useMutation(
    'updateIntroduction',
    (
      requestParams: ApiModel.IntroductionsApiIntroductionsPartialUpdateRequest,
    ) =>
      apiClient.api.IntroductionsApi.introductionsPartialUpdate(requestParams),
    {
      onSuccess: () =>
        // NOTE: match profile is displayed in many views and the data of these queries
        // contains an introduction or introductions_left property that needs to be updated
        Promise.all([
          queryClient.invalidateQueries(queryKeys.marketplaceParentsList),
          queryClient.invalidateQueries(queryKeys.favoriteParentsRetrieve),
          queryClient.invalidateQueries(queryKeys.introductionsUserRetrieve),
          queryClient.invalidateQueries(queryKeys.usersMeRetrieve),
        ]),
      onError: (error: GlobalError) => {
        if (error?.response?.data) {
          toast.error(t('Something went wrong...'));
        }
      },
    },
  );

  const profileInformationWithSections = useMemo(
    () =>
      profileData?.role === apiEnums.UserRoleEnum.Dnr
        ? dnrProfileInformation
        : parProfileInformation,
    [profileData?.role],
  );

  const profileDataWithSections = useMemo(
    () =>
      buildProfileInformationWithSections(
        profileInformationWithSections,
        profileData,
      ),
    [profileData, profileInformationWithSections],
  );

  const onEdit = (title: string, section: SectionTypes, subTitle?: string) =>
    open({
      title,
      subTitle,
      disableClose: section === 'insuranceReview',
      render: () =>
        section === 'photos' ? (
          <EditPhotos />
        ) : (
          <EditForm
            profileSections={profileDataWithSections}
            section={section}
            onCloseModal={close}
          />
        ),
    });

  return {
    userMe,
    introduction,
    onRequestIntroduction: requestIntroduction,
    onUpdateIntroduction: updateIntroduction,
    onEdit,
  };
};

export const MatchProfileConnected: FC<MatchProfileConnectedProps> = ({
  profileData,
  loadingProfile = false,
  ...props
}) => {
  const location = useLocation();
  const { userMe, ...matchProfile } = useMatchProfile(profileData);
  const favorite = useFavoriteProfile(
    profileData.role,
    profileData.id,
    props.owner,
  );
  const { screeningDetails } = useScreeningDetails(
    profileData.id,
    profileData.role,
  );

  const pdfRef = useRef(null);
  const pdfName = userMe?.email;

  useEffect(() => {
    location.hash === '#export' && pdfRef?.current && pdfName
      ? convertToPdf(pdfRef.current, pdfName)
      : null;
  }, [location, pdfRef, pdfName]);

  if (!userMe || loadingProfile || !favorite)
    return <MatchProfileSkeleton inMarketplace={false} />;

  return (
    <div className="w-full" ref={pdfRef}>
      <NotificationTopBar
        status={userMe.status}
        isMatchProfileCompleted={userMe.profile.is_match_profile_completed}
        matchProfileReviewStatus={userMe.profile.match_profile_review_status}
        role={userMe.role}
      />
      <MatchProfile
        {...props}
        {...matchProfile}
        {...favorite}
        profileData={profileData}
        userMe={userMe}
        screeningDetails={screeningDetails}
      />
    </div>
  );
};

import { useApiClient, type ApiModel } from '@nodal/api/src';
import { t } from '@nodal/i18n';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useScreening } from '../useScreening';

import type { InsuranceReviewValues } from './InsuranceReview.interface';

interface UseInsuranceReviewProps {
  submit: (
    values: ApiModel.InsuranceReviewScreen,
  ) => Promise<ApiModel.Screen | undefined>;
  screenId?: string | number;
}

export const useInsuranceReview = ({
  submit,
  screenId,
}: UseInsuranceReviewProps) => {
  const apiClient = useApiClient();
  const { currentStep, updateCurrentStep } = useScreening();

  const initialValues: InsuranceReviewValues = {
    no_insurance: false,
    health_insurance: '',
    insurance_photo_front: null,
    insurance_photo_back: null,
  };

  const screenPartialUpdate = useMutation(
    (request: ApiModel.ScreensApiScreensPartialUpdateRequest) =>
      apiClient.api.ScreensApi.screensPartialUpdate(request),
  );

  const uploadInsurancePhotos = useMutation(
    (request: ApiModel.ScreensApiScreensInsuranceDocumentUpdateRequest) =>
      apiClient.api.ScreensApi.screensInsuranceDocumentUpdate(request, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    {
      onError: () => {
        toast.error(t('Failed to upload insurance photos'));
      },
    },
  );

  const validationSchema = Yup.object().shape({
    no_insurance: Yup.boolean(),
    health_insurance: Yup.string().when('no_insurance', {
      is: false,
      then: Yup.string().required('Required'),
    }),
    insurance_photo_front: Yup.mixed().when('no_insurance', {
      is: false,
      then: Yup.mixed().required('Required'),
    }),
    insurance_photo_back: Yup.mixed().when('no_insurance', {
      is: false,
      then: Yup.mixed().required('Required'),
    }),
  });

  const onSubmit = async (data: InsuranceReviewValues) => {
    if (!screenId) {
      throw new Error('Screening step id is undefined');
    }

    const {
      insurance_photo_front,
      insurance_photo_back,
      no_insurance,
      complete_later,
      ...formValues
    } = data || {};

    const submitData = {
      ...formValues,
      health_insurance: no_insurance ? null : data.health_insurance,
      insurance_photo_front: null,
      insurance_photo_back: null,
    };

    if (!complete_later && !no_insurance) {
      await uploadInsurancePhotos.mutateAsync({
        id: Number(screenId),
        medicalRecordReviewScreenDocuments: {
          insurance_photo_front: insurance_photo_front || null,
          insurance_photo_back: insurance_photo_back || null,
        },
      });
    }

    if (!complete_later) {
      return await submit({ id: Number(screenId), ...submitData });
    }

    if (!currentStep) {
      throw new Error('Screening step is undefined');
    }

    try {
      const data = await screenPartialUpdate.mutateAsync({
        id: currentStep.id,
        patchedScreen: {
          status: 'proc',
        },
      });
      if (data.status === 200) {
        updateCurrentStep!({
          ...data.data,
          index: currentStep.index,
        });
      }
    } catch (error) {
      console.error('Error updating screening step:', error);
    }
  };

  return {
    initialValues,
    validationSchema,
    onSubmit,
  };
};
